import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import "./MobileNav.css"

const MobilNav = ({ openMNav, toggle }) => {
  useEffect(() => {
    console.log(openMNav);
  }, [openMNav])
  return (
    <>
      {openMNav && <nav class="m_navbar">
        <ul class="mnav_links">
          <li className={`res-btn`}>
            <div class="hamburger" onClick={toggle}>
              &#10006;
            </div>
          </li>
          <li><Link className='li' onClick={toggle} to="/">Home</Link></li>
          <li><Link className='li' onClick={toggle}  >NEET</Link></li>
          <li><Link className='li' onClick={toggle} >JEE</Link></li>
          <li><Link className='li' onClick={toggle} >MHT-CET</Link></li>
          <li><Link className='li' onClick={toggle} >MHT-NDA</Link></li>
          <li><Link className='li' onClick={toggle} >BLOGS</Link></li>
          <li><Link className='li' onClick={toggle} to="/ABOUT">ABOUT US</Link></li>
          <li><Link className='li' onClick={toggle} to="/CONTACT">CONTACT US</Link></li>
        </ul>
      </nav>}
    </>
  )
}

export default MobilNav
