import React from 'react'

const Contact = () => {
  return (
    <>
      <div className='Contact-section'>
        {/* <!-- Left Section --> */}
        <div className='Contact-left-section'>
          <h1>Contact us</h1>

          <form action="/submit-form" method="post">
            <div class="form-group">
              <label for="name">Name:</label>
              <input type="text" id="name" name="name" required />
            </div>
            <div class="form-group">
              <label for="city">City:</label>
              <input type="text" id="city" name="city" required />
            </div>
            <div class="form-group">
              <label for="email">Email:</label>
              <input type="email" id="email" name="email" required />
            </div>
            <div class="form-group">
              <label for="phone">Phone Number:</label>
              <input type="tel" id="phone" name="phone" required />
            </div>
            <div class="form-group">
              <label for="query">Your Query:</label>
              <textarea id="query" name="query" required></textarea>
            </div>
            <div class="form-group">
              <button type="submit">Submit</button>
            </div>
          </form>

          <h2>Get in touch</h2>

          <h3>Kota Office:</h3>
          <p>Near Ahimsa Circle, Shrinath Puram, Sector-A
            Kota, Rajasthan</p>

          <h3>Bangalore Office:</h3>
          <p>24th Main Rd,1st Sector, HSR Layout, Bengaluru, Karnataka </p>
          <p>080-4718-9643</p>
          <p>contact@thinkiit.in</p>
        </div>

        {/* <!-- Right Section --> */}
        <div className='Contact-right-section'>
          <div class="Contact-search-container">
            <div class="Contact-search-wrapper">
              <input type="text" class="search-input" placeholder="Search..." />
              <span class="icon">🔍</span>
            </div>
          </div>
          <h2>Recent Posts</h2>
          <div class="Contact-links">
            <a href="#">thinkIIT-thinkNEET Shri Ji Institute All</a>
            <a href="#">India Test Series: The Ultimate</a>
            <a href="#">Guide to Cracking NEET 2024</a>
          </div>

          <h2>Recent Comments</h2>
          <div class="Contact-links">
            <a href="#">SHRIKANT on thinkIIT-thinkNEET Shri Ji Institute All India Test Series: The Ultimate Guide to Cracking NEET 2024</a>
            <a href="#">Jitendra on thinkIIT-thinkNEET Shri Ji Institute All India Test Series: The Ultimate Guide to Cracking NEET 2024</a>
          </div>

          <div class="Contact-search-container">
            <div class="Contact-search-wrapper">
              <input type="text" class="search-input" placeholder="Search..." />
              <span class="icon">🔍</span>
            </div>
          </div>


          <h2>Recent Posts</h2>
          <div class="Contact-links">
            <a href="#">thinkIIT-thinkNEET Shri Ji Institute All</a>
            <a href="#">India Test Series: The Ultimate</a>
            <a href="#">Guide to Cracking NEET 2024</a>
          </div>

          <h2>Recent Comments</h2>
          <div class="Contact-links">
            <a href="#">SHRIKANT on thinkIIT-thinkNEET Shri Ji Institute All India Test Series: The Ultimate Guide to Cracking NEET 2024</a>
            <a href="#">Jitendra on thinkIIT-thinkNEET Shri Ji Institute All India Test Series: The Ultimate Guide to Cracking NEET 2024</a>
          </div>
        </div>
      </div>

      {/* home-pannel12 */}

      <div className='home-pannel12-container'>
        <button className='home-pannel12-view-all-button'>View All Posts</button>
        <div className='home-pannel12-content-container'>
          <div className='home-pannel12-left-section'>
            <div className='home-pannel12-heading'>
              <h1>Shri Ji Institute AITS</h1>
            </div>
            <p>🏆 A Path to Excellence: Shri Ji Institute AITS (All India Test Series) is not just about preparing for exams; it’s about preparing for success. Join the ranks of high achievers who have used AITS as a stepping stone to their dreams.</p>
          </div>
          <div className='home-pannel12-right-section'>
            <h2>Programs</h2>
            <ul className='home-pannel12-links-list'>
              <li><a href='#'>Shri Ji Institute AITS For NEET</a></li>
              <li><a href='#'>Shri Ji Institute AITS For JEE</a></li>
            </ul>
          </div>
        </div>
      </div>

      {/* home-pannel13 */}

      <div className='home-pannel12-container'>
        <div className='home-footer-container'>
          <footer>
            <p>Copyright ©All rights reserved | Developed By TechCherry</p>
          </footer>
        </div>
      </div>
    </>
  )
}

export default Contact
