import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Backend_Url from '../Config/BackendUrl'
import token from '../Config/Token'

const Navbar = ({ openMNav, toggle }) => {

  function toggleMenu() {
    const navLinks = document.querySelector('.nav-links');
    navLinks.classList.toggle('active');
  }


  // const [alldata, setalldata] = useState([]);
  // console.log(alldata);
  // // const [logo , setlogo] = useState(null);

  // useEffect(() => {fetdata();fetlogodata()}, [])

  // const fetdata = async () => {

  //   try {
  //     await fetch(`${Backend_Url}MenuMaster/getall`,
  //       {
  //         method: 'GET',
  //         headers: {
  //           'authorization': 'Bearer ' + token
  //         }
  //       }
  //     )
  //       .then((res) => res.json())
  //       .then((res) => setalldata(res.data))
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }

  // const[logo , setlogo] = useState(null)
  // console.log(logo) 
  // const fetlogodata = async () => {

  //   try {
  //     const response = await fetch(`${Backend_Url}GeneralSetting/get/all`,
  //       {
  //         method: 'GET',
  //         headers: {
  //           'authorization': 'Bearer ' + token,
  //         }
  //       }
  //     );
  //     const data = await response.json();

  //     console.log(data);
  //     setlogo(data)

  //   } catch (error) {
  //     console.log(error)
  //   }
  // }
  // useEffect(() => { fetlogodata();},[] )
  return (
    <>

      {

        <nav class="navbar">
          <div class="logo">
            <img className={`logo-nav`} src='./logo.jpeg' alt="Logo" />
          </div>
          <div class="hamburger" onClick={toggle}>
            &#9776;
          </div>
          {
            openMNav && (
              <div className='nav-links-div'>
                <ul class="nav-links">
                  <li><Link to="/">Home</Link></li>
                  <li><Link >NEET</Link></li>
                  <li><Link >JEE</Link></li>
                  <li><Link >MHT-CET</Link></li>
                  <li><Link >MHT-NDA</Link></li>
                  <li><Link >BLOGS</Link></li>
                  <li><Link to={"/About"}>ABOUT US</Link></li>
                  <li><Link to={"/Contact"}>CONTACT US</Link></li>
                </ul>
              </div>
            )
          }
        </nav>


      }

      {/* <nav className="navbar"> */}
      {/* {logo.map((item, index) => (
    <div className="logo" key={index}>
      <img src={item.Imagepath} alt="Logo" />
      <h1>SHRI JI</h1>
      <div className="nav-text">
        <p>COMPLETE</p>
        <p>EDUCATION SOLUTIONS</p>
      </div>
    </div>
  ))} */}

      {/* {logo?.map((item, index) =>(
    <div className="logo">
    <img src={`${Backend_Url.slice(0, Backend_Url.length-1)}${item.SelectLogo}`} alt="Logo" />
    <h1>SHRI JI</h1>
    <div className="nav-text">
      <p>COMPLETE</p>
      <p>EDUCATION SOLUTIONS</p>
    </div>
  </div>

  ))} */}
      {/* <div className="logo">
          <img src='' alt="Logo" />
          <h1>SHRI JI</h1>
          <div className="nav-text">
            <p>COMPLETE</p>
            <p>EDUCATION SOLUTIONS</p>
          </div>
        </div> */}

      {/* <ul className="nav-links">
          {alldata?.length > 0 ? ( // Check if alldata has items
            alldata.map((item, index) => (
              <li key={index}>
                <Link to={item.URL}>{item.Category_sub}</Link>
              </li>
            ))
          ) : (
            <li>Loading...</li> // Display a loading message or something else when no data
          )}
        </ul>
      </nav> */}

    </>
  )
}

export default Navbar