import { useState } from 'react';
import './App.css';
import About from './components/About';
import Contact from './components/Contact';
import Homepage from './components/Homepage';
import MobilNav from './components/MobilNav';
import Navbar from './components/Navbar';
import { Route, Routes } from 'react-router-dom';

function App() {
  const [openMNav, setOpenMNav] = useState(true);
  const toggle = () => {
    setOpenMNav(!openMNav)
  }
  return (
    <>

      {/* navbar */}
      <Navbar openMNav={openMNav} toggle={toggle} />
      <MobilNav openMNav={openMNav} toggle={toggle} />
      {/* navbar */}

      <Routes>
        <Route path='/' element={<Homepage />} />
        <Route path='/About' element={<About />} />
        <Route path='/Contact' element={<Contact />} />
      </Routes>``

    </>
  );
}

export default App;
